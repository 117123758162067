@import "../../theme/Theme";

.account-content-view {
    @include themify {
        border-left: 1px solid themed("borderColour");
    }

    .account-version {
        @include themify {
            border-bottom: 1px solid themed("borderColour");
        }
        padding-bottom: $mediumSpacing;

        div.account-name,
        div.category {
            margin: 0;
        }

        div.account-name {
            font-size: 16pt;
            margin: $mediumSpacing;
        }

        div.category {
            font-size: $fieldFontSize;
            float: right;
            margin-right: $mediumSpacing;
            padding: $smallSpacing;
            @include themify {
                border: 1px solid themed("borderColour");
            }
        }

        div.field {
            margin-bottom: -10px;
            margin-top: -10px;
            margin-left: 20px;

            .timestamp {
                padding-left: 45px;
                height: 35px;
                display: inline-flex;
                align-items: center;
            }
        }

        div.decrypting-indicator {
            margin-top: $mediumSpacing;
            display: flex;
            justify-content: center;
        }

        span.name {
            width: 150px;
            opacity: 0.7;
            text-align: right;
        }
    }
}
