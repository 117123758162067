@import "../../theme/Theme";

div.round-spin:after {
  border-width: 0 3px 0 0;
  border-style: solid;
  border-radius: 50%;
  display: block;
  width: 50px;
  height: 50px;
  content: "";
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;

  @include themify {
    border-color: themed("textColour");
  }
}

div.round-spin-small:after {
  width: 25px;
  height: 25px;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
