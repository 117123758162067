@import "../theme/Theme";

.content-pane {
  position: relative;
  overflow: hidden;

  div.empty-content {
    @include themify {
      border-left: 1px solid themed("borderColour");
      background: themed("themeColour");
    }
  }
}

.content-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  > div {
    flex: 1 0;

    width: unset;
    overflow: auto;
  }
}

.sync-in-progress {
  height: 40px;
  flex-direction: row;
  @include themify {
    border-left: 1px solid themed("borderColour");
    background: themed("themeColour");
  }

  > div {
    margin: $mediumSpacing;
  }
}

@media screen and (max-width: $smallScreenSize) {
  .content-view.headers-only {
    > :last-child {
      display: none;
    }
  }

  .content-view.content-only {
    > div.content-item-list {
      display: none;
    }
  }
}

.content-view.expanded-content {
  @include themify {
    border-left: 1px solid themed("borderColour");
  }

  > div.content-item-list {
    display: none;
  }
}
