@import "../../theme/Theme";

$sideMargin: 10px;

$warningWidth: 30px;
$warningHeight: 24px;

$opacity: 0.2;

div.email-content-view {
  @include themify {
    background: themed("themeColour");
  }

  div.email-details {
    overflow-x: hidden;

    @include themify {
      background: themed("chromeColour");
      border-bottom: 1px solid themed("borderColour");
    }

    div.email-subject {
      font-size: 20px;
      margin: 8px 0 8px $sideMargin;
      display: flex;
    }

    &.thread-email {
      border-bottom: none;
    }

    &.outgoing-email {
      @include themify {
        border-left: 3px solid themed("successColour");
      }
    }

    span.email-category {
      margin-top: 2px;
      display: flex;
      height: 22px;
      align-items: center;
      justify-content: center;
      text-align: center;

      &.category-Other {
        @include themify {
          color: themed("textColour");
        }
      }
    }

    div.email-from {
      font-size: 16px;
      @include no-wrap;
      margin: 0 0 8px $sideMargin;
      text-overflow: clip;

      button {
        display: inline-flex;

        svg {
          margin-bottom: -6px; // TODO: Not sure why this is required!
          padding-right: $smallSpacing;
          width: 18px;
          height: 18px;
          color: green;
        }
      }
    }

    span.email-date {
      float: right;
      margin-top: $smallSpacing;
      margin-right: $sideMargin;
    }

    div.email-recipient-list {
      margin: 0 0 5px $sideMargin;
    }

    div.read-log {
      display: flex;
      align-items: center;
      padding: 2px $sideMargin 2px 6px;

      > span {
        > span {
          white-space: nowrap;
        }
      }

      > svg {
        padding-right: $smallSpacing;
      }
    }

  }

  div.signature-verified {
    @include themify {
      background: themed("successChromeColour");
    }
  }

  div.signature-invalid {
    @include themify {
      background: themed("errorChromeColour");
    }
  }

  div.email-content {
    &.simple-content {
      padding: 5px;
    }
  }

  div.linked-emails-expander {
    div.expander-title {
      padding-left: $mediumSpacing;
      align-items: center;
      justify-content: space-between;

      @include themify {
        background: themed("chromeColour");
      }
    }

    div.expander-content {
      @include themify {
        background: themed("themeColour");
      }

      .round-spin {
        margin-top: 20px;
      }
    }
  }

  div.email-image img {
    width: 100%;
    height: 100%;
  }

  div.banner {
    display: flex;
    align-items: center;
    white-space: nowrap;

    .header {
      max-height: 24px;

      &.flag {
        font-size: 24px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    button {
      @include no-wrap;
    }

    svg {
      margin-left: 10px;
      margin-right: 10px;
    }

    &.flagged {
      color: red;
    }

    &.pinned {
      @include themify {
        color: themed("primaryColour");
      }
    }
  }

  div.safety-applied {
    svg {
      @include themify {
        color: themed("warningColour");
      }
    }
  }

  div.tracker-detected, div.pgp-error {
    svg {
      @include themify {
        color: themed("errorColour");
      }
    }
  }

  div.ai-probability {
    padding-left: 10px;
    height: $warningHeight;

    @include themify {
      color: themed("errorColour");
    }
  }

  div.ai-agrees {
    @include themify {
      color: themed("successColour");
    }
  }

  div.encrypted svg, div.signature-verified svg {
    @include themify {
      color: themed("successColour");
    }
  }

  div.signature-invalid {
    @include themify {
      color: themed("errorColour");
    }
  }

  .expander-panel div.banner {
    .header {
      transition: transform 250ms;
      transform: translateY(-$warningHeight - 3px);
    }

    .link {
      transition: opacity 250ms;
      opacity: 0;
    }
  }

  .expander-panel.expanded div.banner {
    .header {
      transform: translateY(0px);
    }

    .link {
      opacity: 1;
    }

    &.ai-probability {
      padding-left: 10px;
      height: $warningHeight;
    }
  }

  .expander-panel div.banner-1 .header {
    transform: translateY(-$warningHeight * 2 - 3px) translateX($warningWidth);
  }

  .expander-panel div.banner-2 .header {
    transform: translateY(-$warningHeight * 3 - 3px) translateX($warningWidth * 2);
  }

  .expander-panel div.banner-3 .header {
    transform: translateY(-$warningHeight * 4 - 3px) translateX($warningWidth * 3);
  }

  .expander-panel div.banner-4 .header {
    transform: translateY(-$warningHeight * 5 - 3px) translateX($warningWidth * 4);
  }

  .expander-panel div.banner-5 .header {
    transform: translateY(-$warningHeight * 6 - 3px) translateX($warningWidth * 5);
  }

  .expander-panel div.banner-6 .header {
    transform: translateY(-$warningHeight * 7 - 3px) translateX($warningWidth * 6);
  }

  .expander-panel div.banner-7 .header {
    transform: translateY(-$warningHeight * 8 - 3px) translateX($warningWidth * 7);
  }

  .expander-panel div.banner-8 .header {
    transform: translateY(-$warningHeight * 9 - 3px) translateX($warningWidth * 8);
  }

  .expander-panel div.banner-9 .header {
    transform: translateY(-$warningHeight * 10 - 3px) translateX($warningWidth * 9);
  }

  .expander-panel div.banner-10 .header {
    transform: translateY(-$warningHeight * 11 - 3px) translateX($warningWidth * 10);
  }

  .expander-panel div.banner-11 .header {
    transform: translateY(-$warningHeight * 12 - 3px) translateX($warningWidth * 11);
  }

  // Overrides for emails to make them cleaner!
  .x-email-rio_header, .x-email-footerCard, .x-email-rio_footer, .x-email-prefooter-subheader, .x-email-prefooter-header, .x-email-footer__bottom-description, .x-email-amazon-logo {
    display: none !important;
  }
}

.email-content {
  img {
    // Ensure images aren't too wide for emails
    max-width: 100%;
  }
}