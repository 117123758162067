@import "../../theme/Theme";

div.email-header-status-bar {
  position: absolute;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @include disable-selection;
  
  @include themify {
    background: themed("themeColour");
    border-bottom: 1px solid themed("borderColour");
  }
  
  svg {
    transform: scale(0.8);
    margin-right: $mediumSpacing;
  }
  
  span {
    @include themify {
      color: themed("primaryColour");
    }
  }
}
