@import "../../theme/Theme";

div.compose-email-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100; // reflex sizer has z-index of 99
  @include themify {
    background-color: themed("chromeColour");
  }

  .auto-complete {
    margin: 0;
  }

  div.attachment-list {
    @include themify {
      border-top: 1px solid themed("borderColour");
    }
  }

  div.sidebar {
    padding: $smallSpacing;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 0 0 auto;
  }

  div.editor-column {
    overflow: hidden;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;

    .editor-subject {
      flex: 1 1 auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    .MuiToggleButtonGroup-root {
      margin: 0 $smallSpacing;
    }

    .MuiToggleButton-sizeSmall {
      padding: 2px;
      width: 40px;
      margin: 0;
    }

    button.Mui-selected {
      @include themify {
        color: themed("successColour");
      }
    }
  }

  .rich-text-editor {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: white;
  }

  .tox-toolbar__primary, .tox .tox-toolbar-overlord {
    background: transparent !important;
  }

  .button-container {
    flex: 0 0 auto;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    min-width: 100px;

    button {
      width: 80px;
      margin: 5px;
    }

    button.content-button {
      height: 32px;
      width: 35px;
      min-width: 32px;
    }
  }

  .editor-more-fields {
    display: flex;
    align-items: center;
    padding: $mediumSpacing 0 0;

    button {
      margin-right: $smallSpacing;
    }

    a {
      padding-right: $largeSpacing;
    }
  }
}

.tox-tinymce {
  border: none !important;
  margin-top: 5px;
  //   background: white;
}
