@import "Theme";

.dmarc-header {
  text-align: center;
  padding: 4px;
  margin: 0;
  @include themify {
    border-bottom: 1px solid themed("borderColour");
    background: themed("chromeColour");
  }
}

.xml-document {
  padding: 5px;
}
.xml-element {
  color: $primaryColour;
}

.xml-success {
  @include themify {
    background: themed("successColour");
    color: themed("themeColour");
  }
}

.xml-failed {
  @include themify {
    background: themed("errorColour");
    color: themed("themeColour");
  }
}
