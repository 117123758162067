@import "../../theme/Theme";

button.email-toolbar-menu {
  position: absolute;
  right: 0;
}

.dark .dev-mode .main-toolbar {
  background: #550;
}

.light .dev-mode .main-toolbar {
  background: #FFC;
}

.main-toolbar.offline-toolbar {
  background: rgba(206, 46, 46, 0.5);

  .dev-mode & {
    background: rgba(206, 46, 46, 0.5);
  }
}

.mobile-toolbar {
  min-width: 250px;
  width: 325px;
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiPaper-root.MuiDrawer-paper {
  overflow: hidden;
}

.main-toolbar.main-toolbar-vertical {
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  align-items: flex-start;
  overflow: hidden;

  span.MuiBadge-root {
    margin-left: -5px;
  }

  span.MuiSwitch-sizeSmall {
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
  }

  div.filler {
    flex: 1 0 auto;
    width: 100%;
    @include themify {
      border-bottom: 1px solid themed("borderColour");
    }
  }

  button {
    width: 100%;
    justify-content: flex-start;
    padding-top: $largerSpacing;
    padding-bottom: $largerSpacing;
  }

  div.button-group,
  div.button-group-first,
  div.button-group-last {
    width: 100%;
    flex-direction: column;
    padding: 0;
    margin-bottom: $largeSpacing;
  }

  span.label {
    display: inherit;
    padding-left: 10px;
  }

  .button-avatar {
    padding: $smallSpacing $smallSpacing $smallSpacing 0;
  }
}

svg.add-icon {
  @include themify {
    color: themed("primaryColour");
  }
  position: absolute;
  transform: translate(10px, -8px);
}

svg.add-icon2 {
  position: absolute;
  transform: translate(9px, -7px);
  @include themify {
    color: themed("chromeColour");
  }
}

.main-toolbar {
  height: $toolbarHeight;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  z-index: 1;
  @include themify {
    background-image: themed("toolbarGradient");
    border-bottom: themed("toolbarBorder");
  }

  &.mobile {
    height: $mobileToolbarHeight;

    button {
      height: $mobileToolbarHeight - 10px;

      svg {
        transform: scale(1.3);
      }
    }

    .special-folder-list svg {
      height: $mobileToolbarHeight - 10px;
    }
  }

  .MuiFormControl-fullWidth {
    padding-left: 10px;
    width: calc(100% - 100px);
  }

  span.label {
    display: none;
  }

  > span,
  label {
    margin-left: 10px;
  }

  .button-group,
  .button-group-first,
  .button-group-last {
    button {
      border: none !important;
    }

    border-radius: 0;
    padding-right: 8px;
    padding-left: 8px;
  }

  .button-group,
  .button-group-first {
    @include themify {
      border-right: 1px solid themed("borderColour");
    }
  }

  .button-group-first {
    @include themify {
      border-left: 1px solid themed("borderColour");
    }
  }

  button.drop-down-arrow {
    min-width: 25px;
    width: 25px;
  }

  .user-avatar {
    cursor: pointer;
    width: 30px;
    height: 30px;
    flex: 0 0 auto;
    margin-left: $mediumSpacing;
    margin-right: $mediumSpacing;
    @include themify {
      background-color: themed("primaryColour");
    }
  }

  .filler {
    flex: auto;
  }

  .all-ok {
    @include themify {
      color: themed("successColour");
    }
  }
}
