@import "../../theme/Theme";

div.login-audit-view {

  div.email-grid-header, div.email-grid-item {
    div {
      padding: $mediumSpacing;
    }
  }

  div.grid-location {
    width: 200px;
  }

  div.grid-time {
    width: 200px;
  }

  div.grid-user-agent {
    flex: 1 1 auto;
  }
}