@import "../../theme/Theme";

div.users-list-panel {
  div.panel {
    flex: 0 0 auto;
    padding: $smallSpacing;
  }
}

div.user-grid {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .email-grid-item, .email-grid-header {
    > div {
      padding-left: $smallSpacing;
    }
  }

  .grid-web-id, .grid-mail-id {
    width: 75px;
  }

  .grid-web-un, .grid-mail-un {
    width: 200px;
  }

  .grid-dn {
    flex: 1;
  }

  .mail-user-row {
    font-weight: bold;
  }
}

div.user-edit-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $mediumSpacing;
}