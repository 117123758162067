@import "../../theme/Theme";

.calendar-content-view.edit-calendar {
  > div {
    margin-bottom: $mediumSpacing;
  }

  > div.bottom-space {
    margin-bottom: $largeSpacing;
  }

  div.reminder {
    width: 150px;
  }

  div.timezone {
    margin-top: -$mediumSpacing;
  }

  div.sentence {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin-bottom: $mediumSpacing;
    }

    .right-space {
      margin-right: $mediumSpacing;
    }

    .MuiInput-root, .MuiTextField-root {
      text-align: center;
    }

    .MuiTextField-root.narrow {
      width: 50px;
    }
  }
}
