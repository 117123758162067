@import "../../theme/Theme";

@media screen and (max-width: $smallScreenSize) {
    #root button.hide-content-button {
        display: block;
    }
    #root button.expand-content-button {
        display: none;
    }
}

button.hide-content-button,
button.expand-content-button {
    position: absolute;
    z-index: 2;
    right: 3px;
    height: 32px;
    width: 32px;
    min-width: 48px;
    margin-right: 3px;
    margin-top: 3px;
    background: rgba(0, 0, 0, 0.2);
}

button.hide-content-button {
    display: none;
}

button.expand-content-button {
    display: block;
    transform: scaleX(-1);
}
