@import "../../theme/Theme";

div.email-grid-item.mobile {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    flex: 1 1 auto;
    display: flex;
    align-items: center;

    div.header-from, div.header-subject {
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: $smallSpacing $smallSpacing $smallSpacing $mediumSpacing;
    }

    div.header-from {
      font-size: 20px;
      padding-bottom: 0;
    }

    div.header-subject {
      padding-top: 0;
    }

    div.header-date {
      flex: 0 0 content;
      padding-right: $mediumSpacing;
      text-align: end;
    }

    div.header-category {
      margin-right: $mediumSpacing;
      overflow: hidden;
      width: 80px;
      height: 20px;
      line-height: 20px;
      text-overflow: ellipsis;
      margin-top: -5px;

      &.email-category.category-Other {
        display: none;
      }
    }

    div.flag:not(.flagged), div.flag:not(.pinned) {
      @include themify {
        color: themed("chromeColour");
      }
    }
  }
}

div.email-grid-item.unread {
  @include themify {
    color: themed("primaryColour");
  }
}

div.email-grid-item.unread,
div.email-grid-item.important {
  font-weight: bold;
}

div.email-grid-item.unread.important {
  color: red;
}

div.email-view {
  border-collapse: collapse;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .content-view & {
    overflow: hidden;
  }

  @include themify {
    background: themed("chromeColour");
  }

  .header-attachment {
    width: 30px;
    flex: 0 0 auto;

    @include themify {
      fill: themed("textColour");
    }

    svg {
      height: 24px;
    }
  }

  div.header-from {
    width: 200px;
    padding-left: 3px;

    span.icon {
      svg {
        margin-left: $mediumSpacing;
        fill: green;
        width: 16px;
        top: 2px;
        position: relative;
      }
    }
  }

  div.header-subject {
    flex: 1;
    padding-left: 3px;
    display: flex;
    align-items: center;
  }

  div.header-address {
    flex: 1;
    padding-left: 3px;
  }

  div.header-flag {
    width: 26px;
  }

  span.header-category {
    display: inline-block;
    min-width: 20px;
    max-width: 120px;
    height: 20px;
    line-height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 3px;
    text-align: center;
    overflow: clip;
    text-overflow: ellipsis;
    @include themify {
      color: themed("textColour");
    }
    &.category-Other {
      display: none;
    }
  }

  .email-category {
    &.category-undefined {
      display: none;
    }

    &.category-Personal {
      width: 20px;
      margin-left: -5px;
      margin-right: 8px !important;
    }
  }

  div.header-date {
    width: 110px;
    padding-left: 3px;
    text-align: center;
  }

  span.header-folder {
    font-weight: bold;
    padding-right: $smallSpacing;
    margin-right: $smallSpacing;
    @include themify {
      border-right: 1px solid themed("successColour");
      color: themed("successColour");
    }
  }

  div.header-retry-count {
    width: 90px;
    padding-left: 3px;
  }

  div.header-next-retry {
    width: 140px;
    padding-left: 3px;
  }

  div.email-grid-item div.header-date {
    text-align: center;
  }

  .icon.flag {
    color: transparent;
    cursor: pointer;
  }

  .icon.flag {
    &.flagged {
      color: red;
    }

    &.pinned {
      @include themify {
        color: themed("primaryColour");
      }
    }

    margin-top: 3px;

    svg {
      min-width: 24px;
      min-height: 24px;
      vertical-align: middle;
    }
  }

  .icon.flag:hover {
    @include themify {
      color: themed("themeColour");
      background: themed("borderColour");
    }
  }

  .description {
    margin: 10px;
  }
}

.email-view.requires-sync {
  @include themify {
    border-right: 1px solid themed("borderColour");
    border-left: 1px solid themed("borderColour");
  }
}

.email-category {
  flex: 0 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 0;
  line-height: 24px;
  height: 26px;
  font-weight: normal;
  @include themify {
    background: themed("borderColour");
  }

  &.special-category {
    background: transparent;
    font-family: "emoji";
    font-size: 12pt;
  }
}

.email-category.category-Personal.special-category {
  font-size: 18pt;
}

.email-category.category-OTP {
  @include themify {
    background: themed("otpCategory");
  }
}
