@import "../../theme/Theme";

$folderSprites: url("../../images/FolderSpritesV6.png");
$spriteSize: 64px;

div.folder-tree-pane {
  overflow: hidden !important;
  display: flex;
  flex-direction: column;

  @include themify {
    background-color: themed("darkChromeColour");
  }
}

div.folder-separator {
  flex: 1;
}

div.special-folder-list {
  flex: 0 0 auto;

  .mail-badge {
    display: flex;

    .MuiBadge-badge {
      transform: translate(25%, -25%);
    }
  }

  span.folder-type {
    margin: -16px;
  }

  span.selectable {
    flex: 1;
    padding: $smallSpacing;
    display: flex;
    justify-content: center;
    max-width: 60px;

    > span {
      display: block;
      margin: -16px !important;
    }
  }
}

div.folder-tree {
  overflow: auto;

  .MuiListItem-dense {
    padding-top: 0;
    padding-bottom: 0;
  }

  span.MuiTypography-root {
    display: flex;

    > span {
      height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  > nav > div {
    transition: cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .unread-count {
    padding-left: 10px;
    font-weight: bold;
    @include themify {
      color: themed("primaryColour");
    }
  }

  .context-selected {
    @include themify {
      background-color: themed("primaryColour");
    }
  }
}

div.folder {
  min-width: 200px;
  display: flex;
  align-items: center;
  opacity: 0.5;

  &.synced {
    opacity: 1;
  }
}

span.folder-name {
  margin-left: 10px;
  font-size: 13pt;
}

span.folder-type {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin: 5px 0 0 0;
  padding-top: 2px;
  display: inline-block;
  vertical-align: middle;
}

div.special-folder-list span.folder-type {
  transform: scale(0.5);
}

span.folder-expander {
  visibility: hidden;
  padding: 10px;
  font-size: 8pt;

  &.folder-expandable {
    visibility: visible;
  }
}
