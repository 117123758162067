@import "../../theme/Theme";

div.folder-controls {
  height: 34px;
  display: flex;
  align-items: center;
  margin-bottom: $mediumSpacing;
  
  flex: 0 0 auto;
  position: relative;
  
  > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.visibility-icon {
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 5px;
}

.hide-folders {
  @include themify {
    color: themed("primaryColour");
  }
}
