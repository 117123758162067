@import "../../theme/Theme";

div.auto-complete {
  .MuiChip-root {
    height: 28px;
    margin-bottom: 2px;
    margin-top: -2px;

    .MuiChip-label {
      filter: grayscale(100%);
      padding-top: 1px;      
    }
  }

  div.MuiChip-root.valid {
    @include themify {
      background: themed("successChromeColour2");
    }
  }

  div.MuiChip-root.invalid {
    @include themify {
      background: themed("errorChromeColour2");
    }
  }
}
