@import "../../theme/Theme";


div.button-panel {
  overflow: hidden;
  position: relative;

  > div.content {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

  }

  > div.content.padded {
    padding: $mediumSpacing;
  }

  div.buttons {
    //--scrollbar-width: calc(50vw - 100%);
    position: absolute;
    left: 0;
    right: 10px; // TODO: fix this - it should be the scrollbar width depending on the actual width
    //right: calc(var(--scrollbar-width));

    padding: $smallSpacing;
    background-color: rgba(0, 0, 0, 0.8);

    button {
      margin: $smallSpacing;
    }

    .MuiToggleButtonGroup-root {
      margin: $smallSpacing;
    }
    .MuiToggleButton-root {
      margin: 0;
      height: 32px;
    }

    button.right {
      position: absolute;
      right: $smallSpacing;
    }
  }
}

@supports (backdrop-filter: blur()) {
  div.button-panel div.buttons {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}

div.button-panel.top {
  > div.content {
    padding-top: 52px !important;
  }

  > div.content.padded {
    padding: $mediumSpacing;
    padding-top: calc(52px + 10px) !important;
  }

  div.buttons {
    top: 0;
  }
}

div.button-panel.bottom {
  > div.content {
    padding-bottom: 52px !important;
  }

  div.buttons {
    bottom: 0;
  }
}
