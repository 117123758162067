@import "../../theme/Theme";

div.resize-container {
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;

    > div.resize-splitter {
        @include disable-selection;
        position: relative;
        flex: 0 0 auto;
        z-index: 1;
        > div {
            position: absolute;
            height: 100%;
            width: 8px;
            margin-left: -4px;
        }
    }

    > div {
        flex: 1 1 auto;
    }
}

div.resize-container.vertical {
    flex-direction: row;
    min-width: 1px;

    > div.resize-splitter {
        cursor: w-resize;
        > div {
            cursor: w-resize;
        }
    }
}

div.resize-container.horizontal {
    flex-direction: column;
    min-height: 1px;

    > div.resize-splitter > div {
        cursor: n-resize;
    }
}
