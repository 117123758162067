@import "../../theme/Theme";

div.sp {
  display: flex;

  > * {
    flex: 0 0 auto;
  }
}

div.sp.o-h {
  flex-direction: row;
}

div.sp.o-v {
  flex-direction: column;
}

div.sp.a-middle {
  align-items: center;
}

div.sp.o-h.a-middle {
  justify-content: center;
}

div.sp.a-space-around {
  align-items: center;
}

div.sp.o-h.a-space-around {
  justify-content: space-around;
}

div.sp.a-start {
  align-items: start;
}

div.sp.a-end {
  align-items: end;
}

div.sp.a-stretch {
  align-items: stretch;
}

div.sp.o-h.middle {
  align-items: center;
}

div.sp.o-h.middle {
  justify-items: center;
}

div.sp.o-v.middle {
  justify-content: center;
}

div.sp.fill {
  flex: 1 1 auto;

  > * {
    flex: 1 1 auto;
  }
}

div.sp.wrap {
  flex-wrap: wrap;
}

div.sp {
  .fill {
    flex: 1 1 auto;
  }
}