@import "../../theme/Theme";

$detailOpacity: 0.5;

div.files-buttons {
  .filler {
    flex: 1 1 auto;
  }
}

div.files-container {
  display: flex;

  &.list {
    width: 100%;

    > div {
      width: 100%;
    }

    div.wp {
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;
      
      div.file {

        span.only-on-hover {
          opacity: $detailOpacity;
        }

        .image {
          width: 32px;
          height: 32px;

          img {
            max-width: 32px;
          }
        }

        .details {
          flex-direction: row;
          justify-content: start;
          align-items: center;

          span {
            padding-right: $smallSpacing;

            &.from {
              max-width: 200px;
            }

            &.subject {
              flex: 1 1 auto;
            }

            &.size-and-date {
              width: 200px;
              text-align: end;
            }
          }
        }
      }
    }
  }
  
  &.icons {
    div.file {
      border-radius: 10px;
      width: 350px;
      margin: 3px;
    }
  }

  div.file {
    padding: 2px;

    .image {
      width: 64px;
      height: 64px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    img {
      object-fit: scale-down;
    }

    div.details {
      flex: 1 1 auto;
      overflow: hidden;
      padding-left: $smallSpacing;

      span {
        @include no-wrap;
        opacity: $detailOpacity;
      }

      span.only-on-hover {
        transition: opacity ease 0.2s;
        font-size: 75%;
      }

      span.name {
        font-size: larger;
        opacity: 1;
      }

      span.subject {
        opacity: 1;
      }
    }

    &:hover {
      span.only-on-hover {
        display: inline !important;
        opacity: $detailOpacity !important;
      }
    }
  }

}
