@import "Theme";

.mobile-only {
  display: none;
}

@media screen and (max-width: 760px) {
  .hidden-checkbox {
    visibility: visible !important;
  }

  div.offline-banner {
    top: unset !important;
    bottom: 35px;
  }

  div.main-view .mobile-padding {
    padding: $largerSpacing;
  }

  .mobile-only {
    display: initial;
  }

  div.folder-tree {
    .MuiList-dense {
      .MuiListItem-dense {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      svg.expander {
        padding: 6px;
      }
    }
  }

  div.main-container {
    flex-direction: column-reverse;
  }

  div.bottom-status-bar {
    bottom: unset !important;
    top: 0;
  }

  div.panel-with-status {
    margin: $statusBarHeight 0 0 !important;
  }

  div.home-automation-panel div.device {
    flex: 1 1 auto;
  }

  .main-view {
    div.preferences-container div.preferences-section {
      min-width: initial;
      width: 100%;
    }

    button.hide-content-button,
    button.expand-content-button {
      right: $smallSpacing;
      margin-right: 0;
      margin-top: $smallSpacing;
    }

    div.compose-email-view {
      overflow: auto;

      .tox.tox-tinymce {
        flex: 1 0 auto;

        margin-bottom: calc($smallSpacing + 42px);
      }

      .editor-column .button-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;

        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        @include themify {
          background-color: themed("chromeColour");
        }
      }

      .editor-column .button-container {
        height: calc($smallSpacing + 32px);
        padding: $smallSpacing;
      }
    }
  }

  #root .account-content-view {
    border: none;
  }
}
