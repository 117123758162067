@import "../../theme/Theme";

.email-recipient-list {
  span.prefix {
    font-weight: bold;
    padding-right: 10px;
    font-size: 8pt;
  }

  > span {
    padding-right: $smallSpacing;
  }

  .known-contact {
    font-weight: bold;

    button {
      @include themify {
        color: darken($primaryColour, 5);
      }
    }
  }
}

.contact-tooltip {
  width: 100%;
  max-width: 500px;
}
