@import "../../theme/Theme";

div.email-analysis {
    > div {
        margin: $mediumSpacing;
    }

    .analysis-section {
        padding-bottom: $mediumSpacing;
        @include themify {
            border-bottom: 1px solid themed("borderColour");
        }

        > .title {
            font-size: $titleFontSize;
            text-align: center;
        }

        .spam {
            @include themify {
                color: themed("errorColour");
            }
        }

        .ham {
            @include themify {
                color: themed("successColour");
            }
        }

        .spam-filter-result {
            padding-top: $smallSpacing;

            > span {
                padding-left: $smallSpacing;
                padding-right: $smallSpacing;
            }

            .weight {
                display: inline-block;
                min-width: 40px;
            }

            .type {
                font-weight: bold;
            }

            .description {
                opacity: 0.5;
            }
        }

        .domain-reputation {
            padding-top: $mediumSpacing;
            .name {
                font-size: $smallTitleFontSize;
                font-weight: bold;
            }
            .field {
                padding-top: $smallSpacing;
                > span {
                    padding-right: $smallSpacing;
                }
                .spam,
                .ham,
                .reputation {
                    font-weight: bold;
                }

                .spam {
                    padding-left: $smallSpacing;
                }
            }
        }

        .message-links {
            padding-top: $mediumSpacing;

            .message-id {
                font-weight: bold;
            }

            > span {
                padding-right: $smallSpacing;
            }
        }

        .header {
            .name {
                font-weight: bold;
            }
            .hanging {
                padding-left: 50px;
            }
        }

        .header-data {
            @include themify {
                color: themed("primaryColour");
            }
            padding-top: $smallSpacing;
            .name {
                display: inline-block;
                min-width: 80px;
                padding-right: $smallSpacing;
                font-weight: bold;
            }
        }
    }
}
