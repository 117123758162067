@import "../theme/Theme";

#root, body {
  overflow: hidden;
}

.main-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dark {
  background-color: $chromeColourDark;
  color: $textColourDark;
}

.light {
  background-color: $chromeColourLight;
  color: $textColourLight;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.main-content {
  overflow: hidden;
  flex: 1 1 auto;
}

.create-items-speeddial {
  position: absolute;
  right: 5px;
  bottom: $mobileToolbarHeight + $mediumSpacing;
}
