@import "../../theme/Theme";

.quick-options {
  &.MuiTabs-root {
    display: flex;
    padding: 0;
    font-size: $fieldFontSize;
    min-height: unset;

    .MuiTabs-flexContainer {
      align-items: center;
    }

    button.MuiButtonBase-root {
      padding: 8px 10px;
      min-height: 30px;
      min-width: 70px;
    }
  }
  
  .hide-underline {
    margin-left: 10px;
    flex: 1 1 auto;
  }

  .MuiInputBase-inputAdornedStart {
    margin-bottom: -4px;
  }
  
  .MuiToggleButtonGroup-root {
    .MuiToggleButton-root {
      padding: $smallSpacing;
      min-width: 60px;
    }
  }
}
