@import "../../theme/Theme";

div.contact-view-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include themify {
    background: darken(themed("chromeColour"), 5);
  }
}

div.contact-grid-view, div.contact-category-view {
  overflow: auto;
  display: flex;

  div.card-small {
    cursor: pointer;
    position: relative;
    
    border: 1px solid transparent;
    border-radius: 10px;

    display: flex;
    flex: 1 0 auto;
    align-items: center;

    margin: 7px;
    width: 250px;
    overflow: hidden;
    padding: 4px;

    .name {
      font-size: 16pt;
      opacity: 0.85;
    }

    .detail {
      font-size: 10pt;
      opacity: 0.6;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .category {
      display:block;
      font-size: 8pt;
      margin-top: $smallSpacing;
    }

    &:hover,
    &.selected {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
      @include themify {
        background: lighten(themed("chromeColour"), 5);
      }

      span.contact-avatar {
        border-radius: 25%;
      }
    }
  }
}

div.contact-grid-view {
  flex-wrap: wrap;
  padding: $mediumSpacing;
}

div.contact-category-view {
  flex-direction: column;
  padding: 0;
}

span.contact-avatar {
  display: flex;
  height: 64px;
  width: 64px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
  transition: border-radius ease 0.2s;
  flex-shrink: 0;

  &:hover {
    border-radius: 25%;
  }

  > span {
    height: 64px;
    width: 64px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    @include themify {
      background: darken(themed("chromeColour"), 5);
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.large {
    height: 128px;
    width: 128px;
    margin: 0;

    > span {
      height: 128px;
      width: 128px;
    }
  }

  &.small {
    display: inline-flex;
    height: 48px;
    width: 48px;

    > span {
      height: 48px;
      width: 48px;
    }
  }
}
