@import "../../theme/Theme";

$headerHeight: 25px;
$weekFraction: 14.28571428571429%;
$datePadding: 5px;
$dateOffset: 20px;

div.calendar-view {
  @include disable-selection;
  flex: 2 0;
  display: flex;
  flex-direction: column;

  @include themify {
    border-left: 1px solid themed("borderColour");
  }

  .navigation {
    display: flex;
    align-items: center;
    font-size: 1rem;

    .large-button {
      width: 48px;
      height: 48px;
      border: none;
    }

    .month-select {
      min-width: 120px;
      text-align: center;
    }

    .year-select {
      margin-left: $largeSpacing;
    }

    .MuiToggleButton-root {
      border: none;
      border-radius: 0;
      height: 48px;
    }
  }

  .header {
    height: $headerHeight;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify {
      background: themed("chromeColour");
    }
  }

  .row {
    display: flex;
    width: 100%;
    position: relative;

    flex: 1 1 auto;

    @include themify {
      background-color: themed("themeColour");
    }
  }

  .header-row {
    flex: 0 0 auto;
  }

  .day {
    font-size: larger;
    @include themify {
      border: 1px solid themed("borderColour");
      border-left: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    .date {
      padding-top: 2px;
      border-top: 4px solid transparent;
    }

    .different-month {
      margin-left: $smallSpacing;
      opacity: 0.5;
    }
  }

  .day.today {
    @include themify {
      border: 1px solid themed("secondaryColour");
    }

    .date {
      @include themify {
        color: themed("secondaryColour");
        border-top: 4px solid themed("secondaryColour");
      }
    }
  }

  .date {
    padding: $datePadding 0 $datePadding $datePadding;
  }

  .weekend {
    @include themify {
      background-color: themed("weekendColour");
    }
  }

  .entry {
    @include themify {
      background-color: themed("calendarEntryColour");
    }
    @include no-wrap;
    position: absolute;
    padding: 1px $datePadding;
    top: $dateOffset;
    display: flex;
    height: 17px;

    .time {
      font-weight: bold;
      padding-right: $smallSpacing;
    }

    .cal-icon {
      margin-right: $smallSpacing;
    }
  }

  .cspan-1 {
    width: calc(14.28571428571429% - 11px);
  }

  .cspan-2 {
    width: calc(14.28571428571429% * 2 - 11px);
  }

  .cspan-3 {
    width: calc(14.28571428571429% * 3 - 11px);
  }

  .cspan-4 {
    width: calc(14.28571428571429% * 4 - 11px);
  }

  .cspan-5 {
    width: calc(14.28571428571429% * 5 - 11px);
  }

  .cspan-6 {
    width: calc(14.28571428571429% * 6 - 11px);
  }

  .cspan-7 {
    width: calc(100% - 12px);
  }

  .col-0 {
    left: 0;
  }

  .col-1 {
    left: $weekFraction;
  }

  .col-2 {
    left: $weekFraction * 2;
  }

  .col-3 {
    left: $weekFraction * 3;
  }

  .col-4 {
    left: $weekFraction * 4;
  }

  .col-5 {
    left: $weekFraction * 5;
  }

  .col-6 {
    left: $weekFraction * 6;
  }
}

div.mode-month,
div.mode-week {
  .row {
    .day {
      width: 14.28571428571429%;
    }
  }
}

div.mode-week .row .entry {
  max-height: 50px;
  white-space: normal;
  height: unset;
}