@import "../../theme/Theme";

$transition: 250ms;

.expander-panel .expander-title {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .expander-button {
        width: 24px;
        padding: 3px;
        fill: currentColor;
        path {
            transition: transform $transition;
            transform: rotateZ(180deg);
            transform-origin: center;
        }
    }
}

.expander-panel.expanded .expander-title .expander-button path {
    transform: rotateZ(0);
}
