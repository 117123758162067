@import "../../theme/Theme";

div.notes-view {
  display: flex !important;
  
  div.notes-page-tabs {
    overflow: hidden;

    div.MuiTabs-root {
      flex: 1;
    }
  }

  div.notes-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include themify {
      background: themed("themeColour");
    }
    
    div.notes-html {
      padding: 8px;
      overflow: auto;
      flex: 1 1;
      font-size: 12pt;
    }
    
    div.last-modified {
      bottom: $largeSpacing;
      right: $mediumSpacing;
      position: absolute;
      z-index: 1;
    }
  }

  div.tox-tinymce {
    margin: 0;
  }
}
