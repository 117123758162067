@import "../../theme/Theme";

$sideMargin: 10px;

div.email-thread-view {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include themify {
    background: themed("themeColour");
  }

  > div.email-subject {
    font-size: 20px;
    padding: 2px 55px 2px $sideMargin;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    height: 35px;

    @include themify {
      background: themed("chromeColour");
      border-bottom: 1px solid themed("borderColour");
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiToggleButton-root {
      padding: 4px;
      margin-left: -3px;
      margin-right: $smallSpacing;

      border: none;
    }
  }

  div.email-thread-content {
    flex: 1 1 auto;
    overflow: auto;
  }

  div.email-subject-placeholder {
    line-height: 39px;
  }

  div.unverified-sender, div.content-error {
    padding: 5px;
    text-align: center;
    color: white;
    @include themify {
      background-color: themed("errorColour");
    }
  }
}
