@import "../../theme/Theme";

div.notification-request-banner {
    width: 100%;
    height: 20px;
    z-index: 1001;
    top: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    @include themify {
        background: themed("successColour");
    }
}

div.copy-banner {
    padding: 3px;
    border-radius: $smallSpacing;
}