@import "../../theme/Theme";

div.rules-list {
  div.item:hover {
    div.rank button {
      visibility: visible;
    }
  }

  div.item {
    display: flex;
    padding: $smallSpacing $mediumSpacing;
    overflow: hidden;

    div.sp {
      overflow: hidden;
    }

    div.description {
      flex: 1 1 auto;
    }

    div.title {
      flex: 0 0 auto;
      font-size: $smallTitleFontSize;
      font-weight: bolder;
    }

    div.rank {
      flex: 0 0 auto;

      button {
        visibility: collapse;
        padding: 0;
      }
    }

    div.last-used {
      flex: 0 0 auto;
      padding-left: $smallSpacing;
      text-align: end;
      width: 80px;

      span:first-child {
        opacity: 0.7;
      }
    }

    .conditions, .actions {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.4;
    }

    .param {
      font-weight: bolder;
    }
  }

  div.item.selected {
    .title {
      @include themify {
        color: themed("primaryColour");
      }
    }

    .conditions, .actions {
      opacity: 1;
    }
  }
}

@media screen and (max-width: $smallScreenSize) {
  div.rule-item-view {
    div.condition, div.action, div.field {
      > div {
        button.MuiButton-root.delete {
          opacity: 1;
        }
      }
    }
  }
}

div.rule-item-view {
  padding: $mediumSpacing;

  div.title {
    font-size: $largeTitleFontSize;

    input {
      font-size: $largeTitleFontSize;
    }
  }

  div.field {
    margin-bottom: $largeSpacing;
  }

  button.MuiButton-root.link {
    @include themify {
      color: themed("textColour");
    }
  }

  button.MuiButton-root:hover, input.MuiInput-input:hover, div.MuiSelect-select:hover {
    @include themify {
      color: themed("primaryColour");
    }

    .MuiSvgIcon-root {
      @include themify {
        color: themed("errorColour");
      }
    }
  }

  div.condition, div.action {
    .MuiSelect-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    button.MuiButton-root {
      min-width: 32px;
      width: 32px;
    }
  }

  div.condition, div.action, div.field {
    @include disable-selection;

    button.MuiButton-root.delete {
      opacity: 0;
      transition: 0.2s ease opacity;
    }

    > div:hover {
      button.MuiButton-root.delete {
        opacity: 1;
      }
    }

    > div {
      display: flex;
      align-items: center;

      span {
        flex: 0 0 auto;
        padding-right: $smallSpacing;
        align-self: center;
        padding-bottom: 5px;
      }

      .MuiInput-underline::before {
        border-bottom: 1px solid transparent;
      }

      .MuiFormControlLabel-root {
        align-self: end;
      }

      .MuiCheckbox-root {
        padding: 0 0 0 $mediumSpacing;
        margin-left: $smallSpacing;
      }

      button.MuiButton-root {
        padding: 0 $smallSpacing;
        height: 28px;

        .MuiButton-label {
          padding: 0;
        }
      }

      .number-control {
        width: 50px;

        .MuiInput-input {
          padding: 0;
        }
      }
    }

    .add-item {
      width: 150px;
    }
  }
}