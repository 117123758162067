@import "../../theme/Theme";

div.contact-content-container {
  overflow: hidden;

  > div:not(.edit-buttons) {
    overflow: hidden;
    padding: $largeSpacing 0 0 $largeSpacing;
    display: flex;
  }

  .contact-left-pane {
    padding-right: $largeSpacing;

    button {
      margin-top: $largeSpacing;
      width: 120px;
      white-space: nowrap;
    }
  }

  .contact-left-pane.contact-mobile button {
    width: 80px;
    margin-left: -10px;
    margin-right: -5px;
  }
}

span.field-name {
  display: block;
  font-size: 8pt;
  margin-top: 5px;
  opacity: 0.5;
}

span.field {
  display: flex;
  margin-bottom: 10px;
  font-size: $fieldFontSize;
  align-items: center;
}

span.field-preformatted {
  white-space: pre;
}

.contact-right-pane {
  overflow: auto;
  flex: 1 1 auto;

  .MuiFormControl-fullWidth {
    width: calc(100% - 10px);
  }

  .name {
    font-size: 18pt;
    margin-bottom: $largeSpacing;
  }

  div.category {
    margin-top: $largeSpacing;
  }
}

.contact-right-pane.small {
  .name {
    display: flex;
    align-items: center;
    margin-top: $largeSpacing;
    font-size: 14pt;
  }

  .highlighted {
    @include themify {
      color: themed("primaryColour");
    }
  }
}

.edit-contact {

  > div {
    margin-bottom: $largeSpacing;
  }

  .small-field {
    width: 100px;
    padding-right: $smallSpacing;
    margin-right: $smallSpacing;
    @include themify {
      border-right: 1px solid themed("borderColour");
    }
  }

  div.address-panel {
    width: 100%;

    > div.sp {
      flex: 1 1 auto;

      > div {
        margin-bottom: $mediumSpacing;
      }
    }
  }
}