@import "../../theme/Theme";

div.edit-buttons-container {
    position: relative;

    > :first-child {
        height: calc(100% - 56.5px);
        overflow-y: auto;
    }

    .edit-buttons {
        display: flex;
        position: absolute;
        bottom: 0;
        justify-content: center;
        width: 100%;

        button {
            margin: $mediumSpacing;

            min-width: 80px;
        }
    }
}

.dark .edit-buttons-container .edit-buttons {
    background: rgba(0, 0, 0, 0.5);
}

.light .edit-buttons-container .edit-buttons {
    background: rgba(238, 238, 238, 0.5);
}

@supports (backdrop-filter: blur()) {
    div.edit-buttons-container div.edit-buttons {
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.3);
    }
}
