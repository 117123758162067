@import "../../theme/Theme";

div.pgp-keys-view {
  overflow: hidden;

  div.panel {
    flex: 0 0 auto;
    padding: $smallSpacing;
    align-self: center;

    button {
      margin: 0 $smallSpacing;
    }
  }

  div.panel.top-panel {
    align-self: normal;
  }

  .fingerprint {
    padding: $mediumSpacing;

    div {
      font-family: Consolas, serif;
      font-size: larger;
      @include themify {
        color: themed("primaryColour");
      }
    }
  }

  div.key-info {
    padding: $mediumSpacing 0 $mediumSpacing 0;

    span {
      display: block;
      padding-bottom: $smallSpacing;
    }
  }

  div.left-panel, div.right-panel {
    flex: 1 0;
    overflow: hidden;
  }

  div.pgp-grid {
    display: flex;
    flex-direction: column;
  }

  div.secret-key-row {
    @include themify {
      color: themed("primaryColour");
    }
  }

  div.grid-key-id, div.grid-user, div.grid-expiry {
    padding: 0 $smallSpacing;
  }

  div.grid-icon {
    flex: 0 0 auto;
    width: 32px;
    padding-left: $mediumSpacing;
  }

  div.grid-user {
    flex: 1 1 auto;
  }

  div.grid-key-id {
    flex: 0 0 auto;
    width: 130px;
    text-align: center;
  }

  div.grid-expiry {
    flex: 0 0 auto;
    width: 120px;
    text-align: center;
  }

  div.pgp-item-panel {
    display: flex;
    flex-direction: column;
    padding: $mediumSpacing;
    overflow: auto;

    .MuiAccordionSummary-content {
      margin: $smallSpacing 0;

      > div {
        display: inline-flex;
        align-items: center;
      }
    }

    .group-glyph {
      transform: scale(0.5);
      margin: -8px 0;
    }

    > * {
      flex: 0 0 auto;
    }

    .title {
      font-size: $largeTitleFontSize;
    }

  }

  div.signature {
    height: 32px;
    align-items: center;
  }
}