@import "../../theme/Theme";

div.email-source-button {
    position: absolute;
    z-index: 1;
    right: 0;
    width: auto;
    overflow: hidden;
    margin: $mediumSpacing;
    margin-right: 18px;

    button {
        margin: 0;
        @include themify {
            background: rgba(themed("themeColour"), 0.8);
        }
    }

    button:hover {
        @include themify {
            background: rgba(themed("borderColour"), 0.8);
        }
    }
}

.email-source {
    @include themify {
        background: themed("themeColour");
    }

    .email-body {
        padding: $smallSpacing;
    }

    span.internal-header {
        @include themify {
            color: themed("primaryColour");
        }
    }

    span.header-name {
        opacity: 0.5;
    }

    div.markup.section div.markup.section {
        margin-left: $mediumSpacing;
    }

    span.markup.syntax {
        opacity: 0.5;
    }

    span.markup.content {
        font-weight: bold;
    }

    span.markup.element {
        @include themify {
            color: themed("primaryColour");
        }
    }

    span.markup.attribute-name {
        opacity: 0.7;
    }

    span.markup.attribute-value {
        @include themify {
            color: themed("attributeColour");
        }
    }
}
