@import "../../theme/Theme";

button.protected {
  @include themify {
    color: themed("successColour");
  }
}

button.unprotected {
  @include themify {
    color: themed("errorColour");
  }
}
