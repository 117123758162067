@media print {
  .main-toolbar, .folder-tree-pane, .email-view.content-item-list, .expander-panel, .expand-content-button, .attachment-list, .read-log, .banner, .MuiToggleButtonGroup-root {
    display: none !important;
  }
  
  body, #root, .main-view, .main-container, .main-content, .content-view, .content-pane {
    overflow: unset !important;
  }

  div.resize-container {
    height: unset !important;
  }
}
