@import "./theme/Theme";
@import "./theme/Dmarc";
@import "./theme/Mobile";
@import "./ChromeStyleHacks";

/* Global Classes */

.hide {
  display: none !important;
}

.large-button {
  .MuiSvgIcon-root {
    font-size: 30px;
  }
}

.hidden-checkbox {
  visibility: hidden;
}

span.popup-text {
  padding-left: $mediumSpacing;
}

span.spacer {
  flex: 1;
}

/* Overrides */

.MuiModal-backdrop.MuiBackdrop-root:not(.menu-backdrop, .MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.3);
}

@supports (backdrop-filter: blur()) {
  div:not(.MuiMenu-root) .MuiBackdrop-root:not(.menu-backdrop, .MuiBackdrop-invisible) {
    backdrop-filter: blur(6px);
  }
}

.MuiFormControl-root.number-control .MuiInputBase-input {
  padding-bottom: 4px; // Makes the text appear vertically in the centre
}

.MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  padding-top: 10px; // Makes the text appear vertically in the centre
  padding-right: 30px; // Ensures the down arrow doesn't overlap
}

.MuiTypography-body1 {
  height: 19px; // Makes the text appear vertically in the centre
}

button.MuiButton-text:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

li.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.MuiToggleButtonGroup-root {
  button {
    @include themify {
      color: themed("textColour");
    }

    &.Mui-selected {
      @include themify {
        color: themed("primaryColour");
      }
    }
  }
}

div.MuiDialog-container {
  // Custom buttons for dialogs
  div.MuiPaper-root {
    min-height: 170px;
  }
  div.MuiDialogActions-root {
    align-items: stretch;
    padding: 0;

    button {
      flex: 1;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: unset;
      margin-left: unset;
    }
    button:not(:first-of-type) {
      border-bottom-left-radius: 0;
    }
    button:not(:last-of-type) {
      border-bottom-right-radius: 0;
    }
  }
}

.MuiAlert-filledWarning {
  @include themify {
    background-color: themed("warningColour");
    color: themed("textColour");
  }
}

button.MuiTab-textColorInherit {
  .MuiTabs-flexContainerVertical & {
    height: 40px;
    min-height: 40px;
  }
  
  &.Mui-selected {
    @include themify {
      background-color: themed("themeColour");
    }
  }
}

.MuiTabs-root .MuiTab-root {
  min-width: 120px;
}

.MuiTab-root.add-item {
  min-width: 60px;
  max-width: initial;
}

.MuiTabs-flexContainerVertical .MuiTab-root {
  max-width: unset;
}

.MuiBadge-root {
  align-items: end;
}
div.MuiAccordion-root {
  &.Mui-expanded {
    margin: 5px 0;
  }

  div.MuiAccordionSummary-root {
    min-height: 46px;

    div.MuiAccordionSummary-content {
      margin: 5px 0;
    }
  }

  div.MuiAccordionDetails-root {
    padding: 0;
    
    .contact-grid-view {
      padding: $smallSpacing;
    }
  }
}

button.link {
  background: transparent;
  border: none;
  padding: 0;
}

a,
button.link {
  text-decoration: none;
  cursor: pointer;
}

a:hover,
button.link:hover {
  text-decoration: underline;
}

.icon {
  width: 24px;
  fill: currentColor;
  overflow: visible;
  box-sizing: content-box !important;
}

.icon svg {
  width: 24px;
}

/* Theming */

.selectable,
.selectable-2 {
  cursor: default;
  // border-top-right-radius: 16px !important;
  // border-bottom-right-radius: 16px !important;
  //transition: background-color ease 100ms;
}

.pointer {
  cursor: pointer;
}

.error {
  color: red !important;
}

.success {
  @include themify {
    color: themed("successColour") !important;
  }
}

.primary {
  @include themify {
    color: themed("primaryColour");
  }
}

.primary-background {
  @include themify {
    background: themed("primaryColour");
  }
}

// TODO: use theme colours here instead!!
.dark {
  a,
  button.link {
    color: rgb(20, 180, 233);
  }

  .theme-error {
    color: rgb(182, 0, 0);
  }

  .selectable:hover {
    background: #444 !important;
  }

  .selectable.selected {
    background: #555;
  }

  .selectable.selected:hover {
    background: #666 !important;
  }

  .selectable-2:hover {
    background: #555 !important;
  }
}

.light {
  a,
  button.link {
    color: rgb(20, 158, 204);
  }

  .theme-error {
    color: red;
  }

  .selectable:hover {
    background: #ccc !important;
  }

  .selectable.selected {
    background: #bbb;
  }

  .selectable.selected:hover {
    background: #aaa !important;
  }

  .selectable-2:hover {
    background: #bbb !important;
  }
}

.search-offline {
  input {
    position: relative;
    top: 3px;
  }
  
  @include themify {
    background: rgba(themed("errorColour"), 0.2) !important;
  }
}

.search-highlight {
  @include themify {
    background: rgba(themed("primaryColour"), 0.2) !important;
  }
}

.hide-underline .MuiInput-underline::before {
  border: 0;
  
  &.search-offline {
    border: 0 !important;
  }
}
.hide-underline.search-offline .MuiInput-underline::after {
  border: 0 !important;
}

div.bottom-status-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $smallSpacing;
  display: flex;

  @include themify {
    background: themed("chromeColour");
  }
}

div.panel-with-status {
  margin: 0 0 $statusBarHeight;
}

.flip {
  transform: rotateZ(180deg);
}

button.MuiButton-outlined, button.MuiButton-contained {
  height: $buttonHeight;

  .MuiButton-label {
    margin-top: 5px;
  }
}

select.MuiSelect-select {
  padding-top: 9px;
}

.MuiTooltip-tooltip {
  max-height: 300px;
  overflow: auto;
  @include themify {
    color: themed("textColour");
    background-color: themed("tooltipColour") !important;
    border: 1px solid themed("borderColour");
  }
}

code {
  @include themify {
    padding: 2px 5px;
    color: themed("textColour");
    border-left: 1px solid themed("borderColour");
  }
}

.overflow-hidden {
  overflow: hidden;
}

.expander {
  padding: 2px;
  svg {
    cursor: pointer;
    padding: 4px;
    margin: -5px;
    transition: 0.3s ease transform;
    transform: rotateZ(0);

    &.expanded {
      transform: rotateZ(180deg);
    }
  }
}

// For amazon emails
#legalCopy {
  display: none;
}

@import "./theme/Print";


