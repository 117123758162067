@import "../../theme/Theme";

div.system-logs {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .system-log-select {
    min-width: 200px;
    margin: $smallSpacing;
  }

  div.log-lines {
    overflow: auto;
    cursor: text;
    padding-left: $mediumSpacing;
    padding-right: $mediumSpacing;
    font-family: Consolas, monospace;
    white-space: pre;

    @include themify {
      background: themed("themeColour");
    }

    div.L0 {
      font-weight: bold;
      @include themify {
        color: themed("errorColour");
      }
    }

    div.L1 {
      @include themify {
        color: themed("warningLogColour");
      }
    }

    div.L4 {
      @include themify {
        color: themed("debugLogColour");
      }
    }
  }

  div.log-lines > div {
    opacity: 1;
    animation: fade 1s linear;
  }

  @keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
}
