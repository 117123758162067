@import "../../theme/Theme";

div.notification-message {
  @include disable-selection;

  @include themify {
    border-bottom: 1px solid themed("borderColour");
  }

  span.icon {
    font-size: $titleFontSize;
    color: white;
  }

  span.close {
    cursor: pointer;
    display: flex;
  }

  span.close:hover {
    background-color: rgba(128, 128, 128, 0.5);
  }

  span.message {
    padding: $smallSpacing;
  }
}

div.notification-message.today {
  @include themify {
    background: themed("primaryColour");
    color: white;
  }
}
