@import "../../theme/Theme";

div.folder-selection-overlay {
    @include disable-selection;

    position: absolute;
    right: 0;
    bottom: 0;
    align-items: start;

    font-size: larger;

    background-color: rgba(0, 0, 0, 0.75);
    
    span {
        display: inline-flex;
        align-items: center;
        color: white;

        svg {
            margin-right: 20px;
        }

        button {
            margin-left: 20px;
        }
    }
}

@supports (backdrop-filter: blur()) {
    div.folder-selection-overlay {
        backdrop-filter: blur(6px);
        background-color: rgba(0, 0, 0, 0.3);
    }
}
