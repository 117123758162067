@import "../../theme/Theme";

div.calendar-content-container {

  div.calendar-content-view {
    padding: $mediumSpacing;
  }
}

div.calendar-content-view {
  div.event-section {
    margin-bottom: $mediumSpacing;
    display: inline-flex;
    align-items: center;
    
    div, span {
      font-size: $fieldFontSize;
    }
    
    span.cal-icon {
      font-size: $largeTitleFontSize;
      margin-right: $mediumSpacing;
    }
  }

  div.date-time-info {
    margin-top: $mediumSpacing;
  }
  
  div.timezone {
    opacity: 0.5;
  }
  
  div.event-today {
    @include themify {
      color: themed("successColour");
    }
  }

  .subject {
    font-size: $largeTitleFontSize;
  }

  .connection-text {
    opacity: 0.5;
    padding: 0 $smallSpacing;
  }

  svg {
    margin-right: $smallSpacing;
  }
  
  .icon-indent {
    margin-left: 24px + $smallSpacing;
  }
}