@import "../../theme/Theme";

div.attachment-list {
  padding-top: 5px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  @include themify {
    background: themed("chromeColour");
  }

  .pending-upload {
    opacity: 0.5;
  }

  > span {
    height: 64px;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    max-width: 200px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 2px solid transparent;

    > span {
      padding-left: 5px;
      white-space: normal;
      overflow: hidden;
      overflow-wrap: break-word;
    }

    > img {
      max-width: 64px;
      max-height: 64px;
      flex: 0 0 auto;
    }
  }

  > span.selected {
    @include themify {
      border-bottom: 2px solid themed("primaryColour");
    }
  }
  
  &.context-menu-open {
    .context {
      @include themify {
        background-color: themed("primaryColour");
      }
    }
  }
}

div.attachment-list.small {
  > span {
    height: 32px;
    > img {
      max-width: 32px;
      max-height: 32px;
    }
  }
}

pre.text-attachment {
  white-space: pre-wrap;
}
