@import "../../../theme/Theme";

div.email-grid-header {
  font-weight: bold;
  height: 30px;
  overflow-y: scroll;
  flex: 0 0 auto;

  @include themify {
    border-top: 1px solid themed("borderColour");
    border-bottom: 1px solid themed("borderColour");
  }

  div.header-checkbox span {
    display: inline-flex;
  }

  > div {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    //@include themify {
    //  border-right: 1px solid themed("borderColour");
    //}
  }
}

div.header-checkbox {
  width: 30px;

  span {
    display: none;
  }

  .MuiCheckbox-root {
    padding: 3px;
  }

  border-right: none;
}

div.header-filterable {
  cursor: pointer;
}

div.header-filterable.header-filtered {
  @include themify {
    background: themed("primaryColour");
  }
}

div.header-option {
  padding-left: 5px;
}

div.grid-container {
  height: 100%;

  div.grid-segment-row {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 28px !important;
    @include themify {
      background: themed("darkChromeColour");
      border-bottom: 1px solid themed("borderColour");
      border-top: 1px solid themed("borderColour");
    }
    
    div.title {
      padding-left: $largeSpacing;
    }
  }

  div.email-grid-item:hover, div.email-grid-item.selected {
    div.header-checkbox span {
      display: inline-flex;
    }
  }

  div.mobile div.header-checkbox {
    display: none !important;
  }

  div.grid-item-even {
    @include themify {
      background: themed("gridEvenColour");
    }
  }

  div.grid-item-odd {
    @include themify {
      background: themed("gridOddColour");
    }
  }

  div.email-grid-item.selected {
    @include themify {
      background: themed("gridSelectColour");
    }
  }

  div.email-grid-item, div.grid-loading {
    height: 30px;

    > div {
      @include themify {
        border-right: 1px solid transparent;
      }
    }
  }
}

div.grid-loading {
  @include disable-selection;

  display: flex;
  align-items: center;
  justify-content: center;
}

div.email-grid-header, div.email-grid-item {
  @include disable-selection;

  display: flex;
  align-items: center;

  > div {
    flex: 0 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
