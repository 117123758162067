@import "../../theme/Theme";

div.system-status-panel {
  overflow: auto;

  div.status-panel-list {
    display: flex;
    flex-wrap: wrap;
    padding: $mediumSpacing $mediumSpacing $statusBarHeight;

    .status-panel {
      min-width: 400px;

      span.title {
        font-size: $titleFontSize;
      }
    }
  }

  div.item-status {
    display: flex;
    align-items: center;
    margin-top: $mediumSpacing;
    margin-bottom: $mediumSpacing;

    svg {
      margin-right: $mediumSpacing;
    }

    span.sub-heading {
      opacity: 0.5;
      font-size: smaller;
    }

    .sub-status {
      display: flex;
      flex-direction: column;
    }
  }

  div.item-status:last-of-type {
    margin-bottom: $largeSpacing;
  }

  .status-ok {
    @include themify {
      color: themed("successColour");
    }
  }

  .status-error {
    @include themify {
      color: themed("errorColour");
    }
  }

  .status-unknown, .status-warning {
    @include themify {
      color: themed("warningColour");
    }
  }
}

