@import "../../theme/Theme";

.account-content-view {
    .account-item {
        input[type="text"]:disabled {
            @include themify {
                color: themed("textColour");
            }
        }

        > div.name,
        div.category,
        div.field {
            margin: $mediumSpacing $mediumSpacing $largeSpacing;
        }

        > div.name {
            font-size: 32pt;
        }

        div.category,
        div.category input[type="text"] {
            font-size: 16pt !important;
            
            display: inline-flex;
            align-items: center;
        }

        .category-card {
            margin-top: -10px;
            margin-bottom: -32px;
        }

        div.new-account {
            margin: $largeSpacing;
            text-align: center;
            
            button {
                width: 200px;
            }
        }

        div.field {
            display: flex;
            align-items: center;
            clear: both;

            > div {
                flex: 1 1 auto;
                max-width: 400px;
            }

            div.field-data {
                width: 400px;
                font-size: 16px;
            }
            div.name {
                transform: translate(0, 1.5px) scale(0.75);
                transform-origin: top left;
                opacity: 0.5;
                cursor: default;
            }
            
            div.value.Password {
                font-family: Consolas, monospace;
            }
        }

        div.field.spinner {
            justify-content: center;
        }

        span.card-glyph {
            transform: scale(1) translateY(-16px) !important;
            margin: 0 !important;
            float: right;
        }
    }
}
