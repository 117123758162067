@import "../../theme/Theme";

div.preferences-container {
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  min-height: 150px;

  div.preferences-section {
    flex: 1 1 auto;
    min-width: 500px;
  }
}

div.preferences-section {
  margin: $mediumSpacing;
  
  .MuiCardContent-root {
    padding-top: $mediumSpacing;
    padding-bottom: $mediumSpacing;
  }
  .MuiCardHeader-root {
    padding-bottom: 0;
  }
  
  button {
    margin-left: $mediumSpacing;
  }
  
  .alias-list {
    height: 300px;
    overflow: auto;
    margin-top: $mediumSpacing;
    
    @include themify {
      border: 1px solid themed("borderColour");
    }
    
    > div {
      padding: $smallSpacing;
    }
  }
}
