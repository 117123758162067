$smallScreenSize: 1600px;

// Colours

$primaryColour: #08f;

$chromeColourDark: #282c34;
$chromeColourLight: rgb(238, 238, 238);

$borderColourDark: lighten($chromeColourDark, 20%);
$borderColourLight: darken($chromeColourLight, 10%);

$textColourDark: #ddd;
$textColourLight: #333;

$errorBarColourDark: #a55;
$errorBarColourLight: #f88;

$smallSpacing: 5px;
$mediumSpacing: 10px;
$largerSpacing: 12px;
$largeSpacing: 20px;

$largeTitleFontSize: 20pt;
$titleFontSize: 22px;
$smallTitleFontSize: 16px;
$fieldFontSize: 12pt;

$toolbarHeight: 40px;
$mobileToolbarHeight: 64px;

$statusBarHeight: 26px;

$buttonHeight: 32px;

title {
  display: block;
  font-size: 1.5rem;
  font-family: "Segoe UI", "Segoe UI Web Regular", "Segoe UI Symbol", "Helvetica Neue", "BBAlpha Sans", "S60 Sans", Arial, "sans-serif";
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

// Theme properties
$themes: (
  light: (
    themeColour: white,
    tooltipColour: rgba(238, 238, 238, 0.9),
    primaryColour: $primaryColour,
    secondaryColour: #ff5555,
    warningColour: #ffb120,
    errorColour: #d13535,
    warningLogColour: #714301,
    debugLogColour: #888,
    successColour: #35a135,
    borderColour: darken($chromeColourLight, 10%),
    textColour: $textColourLight,
          chromeColour: $chromeColourLight,
          darkChromeColour: darken($chromeColourLight, 10%),
          errorBarColour: $errorBarColourLight,
          toolbarGradient: linear-gradient(#fff, #e8e8e8),
          toolbarBorder: 1px solid #ccc,
          paperColour: white,
          billCategory: #ffffdd,
          newsletterCategory: #ddddff,
          personalCategory: #ccffcc,
          ticketCategory: #aaddaa,
          shoppingCategory: #88ccff,
          socialCategory: #ccfaff,
          promotionCategory: #fffadd,
          alertCategory: #ffcccc,
          otpCategory: #ff5555,
          attributeColour: #af7200,
          quickSelectColour: red,
          weekendColour: #eeeeff,
          calendarEntryColour: #88ccff,
          gridOddColour: #ecedee,
          gridEvenColour: #ffffff,
          gridSelectColour: #8DCED9,
          successChromeColour: #D8EED8,
          errorChromeColour: #EEE0E0,
          successChromeColour2: #CFE6CF,
          errorChromeColour2: #E8D8D8,
  ),
  dark: (
    themeColour: black,
    tooltipColour: rgba(0, 0, 0, 0.8),
    primaryColour: $primaryColour,
    secondaryColour: #AA0000,
    warningColour: #ffb120,
    errorColour: #aa0000,
    warningLogColour: #ffb120,
    debugLogColour: #888,
    successColour: #008800,
    borderColour: lighten($chromeColourDark, 20%),
          darkChromeColour: darken($chromeColourDark, 5%),
    textColour: $textColourDark,
          chromeColour: $chromeColourDark,
          errorBarColour: $errorBarColourDark,
          toolbarGradient: linear-gradient(#555, #444),
          toolbarBorder: 1px solid #000,
          paperColour: #424242,
          billCategory: #444400,
          newsletterCategory: #330044,
          personalCategory: #005500,
          ticketCategory: #113f11,
          shoppingCategory: #004488,
          socialCategory: #0066aa,
          promotionCategory: #664400,
          alertCategory: #550000,
          otpCategory: #880000,
          attributeColour: #be7c00,
          quickSelectColour: red,
          weekendColour: #110033,
          calendarEntryColour: #004488,
          gridOddColour: #262c2e,
          gridEvenColour: #2d3436,
          gridSelectColour: #005880,
          successChromeColour: #1D301D,
          errorChromeColour: #301D1D,
          successChromeColour2: #3D603D,
          errorChromeColour2: #603D3D,
  ),
);

// Mixins
@mixin no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

@mixin disable-selection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.disable-selection {
  @include disable-selection;
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@function sprite($x, $y, $size) {
  @return ($x * -$size) ($y * -$size);
}
