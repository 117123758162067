@import "../../theme/Theme";

$accountSprites: url("../../images/AccountGlyphs.png");
$cardSprites: url("../../images/CardGlyphs.png");
$spriteSize: -48px;
$cardSpriteSize: -128px;
$categoryGlyphSize: -48px;

div.quick-options {
    flex: 0 0 auto;
    padding: $smallSpacing;
    .MuiFormControl-fullWidth {
        margin-left: 0;
    }
}

.accounts-view {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    
    .share-icon {
        position: absolute;
        left: 4px;
        opacity: 0.7;
    }

    div.accounts-list {
        overflow-y: auto;
        padding: $smallSpacing;

        > div {
            overflow-y: hidden;
        }

        .category {
            font-size: 16pt;
            transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            @include themify {
                background-color: darken(themed("paperColour"), 5%);
            }

            > div {
                display: flex;
                align-items: center;
                margin: $smallSpacing 0;
            }
        }
    }
}

.accounts-view, .account-item, .pgp-item-panel {
    .group-glyph {
        width: -$categoryGlyphSize;
        height: -$categoryGlyphSize;
        display: inline-block;
        flex: none;
        margin-right: $mediumSpacing;
        background: $accountSprites 0 0;
    }

    .glyph-programming {
        background: $accountSprites (1 * $categoryGlyphSize) 0;
    }

    .glyph-banking {
        background: $accountSprites (2 * $categoryGlyphSize + 1) 0;
    }

    .glyph-cards {
        background: $accountSprites (3 * $categoryGlyphSize) 0;
    }

    .glyph-online {
        background: $accountSprites (0 * $categoryGlyphSize) (1 * $categoryGlyphSize);
    }

    .glyph-media {
        background: $accountSprites (1 * $categoryGlyphSize) (1 * $categoryGlyphSize);
    }

    .glyph-messaging {
        background: $accountSprites (2 * $categoryGlyphSize) (1 * $categoryGlyphSize);
    }

    .glyph-social {
        background: $accountSprites (3 * $categoryGlyphSize) (1 * $categoryGlyphSize);
    }

    .glyph-gaming {
        background: $accountSprites (0 * $categoryGlyphSize) (2 * $categoryGlyphSize);
    }

    .glyph-email {
        background: $accountSprites (1 * $categoryGlyphSize) (2 * $categoryGlyphSize);
    }

    .glyph-sports {
        background: $accountSprites (2 * $categoryGlyphSize + 1) (2 * $categoryGlyphSize);
    }

    .glyph-nene {
        background: $accountSprites (3 * $categoryGlyphSize) (2 * $categoryGlyphSize);
    }

    .glyph-random,
    .glyph-uncategorized {
        background: $accountSprites (0 * $categoryGlyphSize) (3 * $categoryGlyphSize);
    }

    .glyph-utilities {
        background: $accountSprites (1 * $categoryGlyphSize) (3 * $categoryGlyphSize + 1);
    }

    .glyph-health,
    .glyph-favourites,
    .glyph-healthcare {
        background: $accountSprites (2 * $categoryGlyphSize) (3 * $categoryGlyphSize);
    }

    .glyph-wifi,
    .glyph-wireless {
        background: $accountSprites (3 * $categoryGlyphSize) (3 * $categoryGlyphSize);
    }

    .glyph-government {
        background: $accountSprites (0 * $categoryGlyphSize + 1) (4 * $categoryGlyphSize);
    }

    .glyph-travel {
        background: $accountSprites (1 * $categoryGlyphSize) (4 * $categoryGlyphSize);
    }

    .glyph-jp {
        background: $accountSprites (2 * $categoryGlyphSize) (4 * $categoryGlyphSize);
    }

    .glyph-food {
        background: $accountSprites (3 * $categoryGlyphSize) (4 * $categoryGlyphSize);
    }

    div.account-summary {
        padding-left: 25px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span.name {
            font-size: $fieldFontSize;
            padding-left: $mediumSpacing;
        }
    }
}

.accounts-view,
.account-item {
    span.glyph,
    span.small-glyph {
        display: inline-block;
        width: -$spriteSize;
        height: -$spriteSize;
        vertical-align: middle;
        background: $accountSprites 0 0;
    }

    span.small-glyph {
        transform: scale(0.6);
        margin: -6px;
    }

    span.card-glyph {
        width: -$cardSpriteSize;
        height: -$cardSpriteSize;
        transform: scale(0.5);
        margin: -32px;
        margin-top: -40px;
        margin-bottom: -40px;
    }
    span.card-UnknownCard {
        background: $cardSprites 0 0;
    }
    span.card-MasterCard {
        background: $cardSprites $cardSpriteSize $cardSpriteSize;
    }
    span.card-Amex {
        background: $cardSprites 2 * $cardSpriteSize 0;
    }
    span.card-Visa {
        background: $cardSprites $cardSpriteSize 0;
    }
    span.card-Clubcard {
        background: $cardSprites 2 * $cardSpriteSize $cardSpriteSize;
    }

    .auto-complete {
        display: block;
        width: 200px;
    }
}

.dark .accounts-view {
    div.account-summary:hover {
        background: #555;
    }
}
